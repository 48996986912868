.container {
  --color-font-base: #ffffff;
  --color-font-secondary: rgba(255, 255, 255, 0.7);
  --color-font-tertiary: rgba(255, 255, 255, 0.5);
  --color-font-disabled: rgba(255, 255, 255, 0.3);
  --color-border-default: rgba(255, 255, 255, 0.2);
  --color-border-subtle: rgba(255, 255, 255, 0.1);
  --color-font-danger: #ffcccb;
  --color-font-button-primary: #ffffff;
  --color-font-button-primary-hover: #ffffff;
  --color-font-button-primary-active: #ffffff;
  --form-control-background-color: rgba(255, 255, 255, 0.05);
  --form-control-border-color: rgba(255, 255, 255, 0.1);
  --form-control-box-shadow-focus: none;
  --color-border-active: var(--color-brand-light-purple);
  --color-background-disabled: rgba(255, 255, 255, 0.2);

  --color-background-button-primary: rgba(255, 255, 255, 0.15);
  --color-background-button-primary-hover: rgba(255, 255, 255, 0.1);
  --color-border-button-primary: rgba(255, 255, 255, 0.15);
  --color-border-button-primary-hover: var(--color-brand-light-purple);
  --color-border-button-primary-active: var(--color-brand-light-purple);
  --button-box-shadow-focus: none;

  background-color: #0d0d0d;
  overflow: hidden;
}

.container a,
.container a:visited {
  text-decoration: underline;
  color: var(--color-font-secondary);
}

.linearGlow {
  position: absolute;
  background: linear-gradient(180deg, #232323 0%, #1c1c1c 25%, #0d0d0d 50%);
  display: flex;
  top: 0;
  width: 100%;
  height: 982px;
  animation: fadeIn 1s ease-out;
  z-index: 0;
}

.centerGlow {
  position: absolute;
  min-width: 1512px;
  min-height: 982px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background: radial-gradient(61.15% 87.06% at 50% 53.4%, rgba(180, 125, 255, 0.25) 0%, rgba(75, 50, 170, 0) 100%);
  mix-blend-mode: hard-light;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: fadeIn 3s ease-out;
  z-index: 0;
}

.upperRightGlow {
  position: absolute;
  top: 0;
  right: -200px;
  width: 1512px;
  height: 982px;
  flex-shrink: 0;
  background: radial-gradient(
    70.42% 110.56% at 130.82% -50.22%,
    rgba(255, 145, 30, 0.75) 0%,
    rgba(220, 80, 255, 0) 100%
  );
  mix-blend-mode: hard-light;
  transition: transform 0.2s ease-out;
  animation-name: fadeInLeft;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  z-index: 0;
}

.registration-input {
  border-radius: 4px;
  border: 1px solid var(--10-white, rgba(255, 255, 255, 0.1));
  height: 48px;
  max-height: 48px !important;
  width: 100%;
  transition: color 0.1s, background-color 0.1s;

  /* dropshadow */
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.registration-input:disabled,
.registration-input:disabled:hover {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.registration-button:focus,
.registration-button:focus-visible,
.registration-input:focus,
.registration-input:focus-visible {
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-button-primary-active);
}

.registration-input::placeholder {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.4);
}

.registration-button {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.5px);
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.1s, border-color 0.2s, color 0.1s;
  text-decoration: none !important;
}

.registration-button:hover {
  border-color: var(--color-brand-light-purple);
}

.registration-button:disabled,
.registration-button:disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media screen and (min-width: 768px) {
  .upperRightGlow {
    right: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  25% {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.zoomIn {
  animation: zoomIn 1s ease-out;
}
