.one-column {
  grid-template-columns: repeat(1fr);
}

@media (min-width: 680px) {
  .two-column-tablet {
    grid-template-columns: 1fr 1fr;
  }
  .three-column-tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .three-column-desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
